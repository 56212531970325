<button
  [ngClass]="'btn--' + type"
  [class.gradient]="hasGradient"
  mat-raised-button
  class="btn"
  [disabled]="disabled"
  (click)="clickEvent.emit($event)"
>
  <ng-container *ngIf="title; else content">
    {{ title }}
  </ng-container>
  <ng-template #content>
    <ng-content />
  </ng-template>
</button>
