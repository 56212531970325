import { NgClass, NgIf } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'app-btn',
  standalone: true,
  imports: [NgClass, MatButtonModule, NgIf],
  templateUrl: './btn.component.html',
  styleUrl: './btn.component.scss',
})
export class BtnComponent {
  @Input() title!: string
  @Input() hasGradient!: boolean
  @Input() disabled!: boolean
  @Input() type: 'primary' | 'secondary' = 'primary'

  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()
}
